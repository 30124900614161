import { Category } from "models/models";
import { OnChangeSelectEvent } from "components/Form";

const FormCategoryOption = ({ category }: { category: Category }) => {
  return (
    <option key={`category-${category.name}`} value={category.id}>
      {category.name}
    </option>
  );
};

type Props = {
  selectedCategoryId: number;
  categories: Category[];
  onChange: OnChangeSelectEvent;
};

export default function FormCategory({
  selectedCategoryId,
  categories,
  onChange,
}: Props) {
  return (
    <select
      name="category_id"
      className="p-2 w-full rounded-lg dark:bg-slate-700"
      value={selectedCategoryId}
      onChange={onChange}
    >
      <option key="category-null" value="-1">
        Wybierz ...
      </option>
      {categories.map((category) => (
        <FormCategoryOption
          key={`category-${category.id}`}
          category={category}
        />
      ))}
    </select>
  );
}
