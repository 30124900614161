import Footer from "components/Footer";
import { NavComponent } from "features/nav/Nav";
import { Children } from "utils/reactUtils";
import { ReactComponent as UpperCurve } from "./UpperCurve.svg";

export default function MainLayout({ children }: Children) {
  return (
    <>
      <div className="absolute -z-10 overflow-hidden w-full h-full">
        <UpperCurve className="absolute -z-10 w-full h-auto top-0" />
      </div>      
      <div className="flex flex-col min-h-screen dark:bg-gray-800 dark:text-slate-200">
        <NavComponent />
        <div className="flex flex-col flex-1 justify-between p-2">
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
}
