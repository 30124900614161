import { FormBox } from "components/Form";
import { CenterInside } from "layouts/CenterInside";
import MainLayout from "layouts/MainLayout";
import { useRef } from "react";
import { useGetDocumentQuery } from "services/docs";
import "./ServerDocument.css";

export default function ServerDocumentPage({
  documentType,
}: {
  documentType: string;
}) {
  const contentRef = useRef<HTMLDivElement>(null);
  const { data: document } = useGetDocumentQuery(documentType);

  if (document?.content && contentRef.current) {
    contentRef.current.innerHTML = document?.content;
  }
  return (
    <MainLayout>
      <CenterInside>
        <FormBox>
          <div ref={contentRef}></div>
        </FormBox>
      </CenterInside>
    </MainLayout>
  );
}
