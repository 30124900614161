const DAYS_MINUTES = 1440;

export function getDateNow(): Date {
  const now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
  return now;
}

export function formatDateStr(date: Date, format: "date" | "datetime" = "datetime") : string {
  const dateStr = date.toISOString();
  return format === "date" ? dateStr.slice(0, 10) : dateStr.slice(0, 16); 
}

export function getDateStr(daysTimespan: number = 0): Date {
  const date = new Date();
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset() - daysTimespan * DAYS_MINUTES);
  return date;
}

export function formatDate(dateString: string): string {
  if (!dateString.includes("T")) {
    return dateString.replace(" ", "T");
  } else {
    return dateString;
  }
}

export function getHourMinute(dateString: string): string {
  return dateString.substring(11, 16);
}

export function extractDateStr(dateString: string): string {
  return dateString.substring(0, 10);
}

export function parseDate(dateString: string): Date {
  return new Date(dateString);
}

export function getDayName(date: Date): string {
  return date.toLocaleDateString(navigator.language, { weekday: "long" });
}

export type ElapsedTime = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  text: string;
}

export function getElapsedTime(dateString: string): ElapsedTime {
  const date = parseDate(dateString);
  const now = new Date();
  const elapsedSeconds = (now.getTime() - date.getTime()) / 1000;
  const minutes = Math.floor((elapsedSeconds / 60) % 60);
  const hours = Math.floor((elapsedSeconds / 3600) % 24);
  const days = Math.floor((elapsedSeconds / 86400) % 365);
  const result = {
      days,
      hours,
      minutes,
      seconds: elapsedSeconds,
  };
  if (days > 0) {
    return { 
      ...result,
      text: `${days} dni ${hours} godzin ${minutes} minut` 
    };
  } else if (hours > 0) {
    return {
      ...result,
      text: `${hours} godzin ${minutes} minut`
    };
  } else {
    return {
      ...result,
      text: `${minutes} minut`
    };
  }
}
export function isTodaysDate(date: string) : boolean {
  return date === formatDateStr(getDateNow(), "date");
}
