import {
  ElapsedTime,
  getElapsedTime,
  isTodaysDate,
  parseDate,
} from "utils/date";
import { Categories, Units } from "./Category";

export type EventReadOnly = {
  category_id: number;
  category_name?: string;
  unit_id?: number;
  unit_name?: string;
};

export type Event = EventReadOnly & {
  id: number;
  name: string;
  description: string;
  price: number;
  created_date: string;
};

export type EventAdapter = Event & {
  getElapsedTime: () => ElapsedTime;
  isTodayEvent: boolean;
};

const createEventAdapter = (e: Event, isTodayEvent: boolean) => {
  return {
    ...e,
    getElapsedTime: () => getElapsedTime(e.created_date),
    isTodayEvent: isTodayEvent,
  };
};

export interface IDailyEvents {
  events: EventAdapter[];
  date: string;
}

export class DailyEvents implements IDailyEvents {
  events: EventAdapter[] = [];
  date: string = "";
  _isTodaysDate = false;

  constructor(events: Event[], date: string) {
    this.date = date;
    this._isTodaysDate = isTodaysDate(date);
    this.events = events
      .map((e) => createEventAdapter(e, this._isTodaysDate))
      .sort((a, b) => a.getElapsedTime().seconds - b.getElapsedTime().seconds);
  }

  getTotalFeedingAmount(): number {
    const numOr0 = (n) => (isNaN(n) ? 0 : parseInt(n));
    return this.events
      .filter((e) => e.category_id === Categories.Feeding)
      .reduce(
        (sum: number, e: EventAdapter) => numOr0(sum) + numOr0(e.price),
        0
      );
  }
  getFeedingCount(): number {
    return this.getFeedingEvents().length;
  }
  getYear(): string {
    return this.date.substring(0, 4);
  }
  getMonth(): string {
    return this.date.substring(5, 7);
  }
  getDay(): string {
    return this.date.substring(8, 10);
  }
  isToday(): boolean {
    return this._isTodaysDate;
  }
  getDayOfTheWeek(): number {
    const d = parseDate(this.date);
    return d.getDay();
  }
  getFeedingEvents(): EventAdapter[] {
    return this.events.filter(event => event.category_id === Categories.Feeding || event.category_id === Categories.SolidFood);
  }
  getFeedingElapsedTime(): string {
    const feedingEvents = this.getFeedingEvents();
    const lastFeedingEvent = feedingEvents[0] || null;
    if (!lastFeedingEvent) {
      return ""; 
    }
    return lastFeedingEvent.getElapsedTime().text;
  }
}
