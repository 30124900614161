import { Children } from "utils/reactUtils";

type Props = Children & {
  hoverEnabled: boolean;
};

export default function DailyEventBox({ children, hoverEnabled }: Props) {
  const styles = hoverEnabled
    ? "hover:bg-slate-200 dark:hover:bg-slate-700 hover:shadow-md"
    : "";
  return (
    <div
      className={`mb-2 ml-2 mr-2 self-stretch box-content border-2 rounded-lg p-4 dark:text-slate-300 dark:bg-slate-700 dark:border-slate-500 bg-slate-100 ${styles}`}
    >
      {children}
    </div>
  );
}
