import { ReactComponent as SearchIcon } from "assets/search_icon.svg";
import { useSearchBox } from "features/event/Search";
import { translations } from "locales/translations";
import { useTranslation } from "react-i18next";
import { NavBarItem } from "./NavBar";

export default function NavFilter() {
  const { t } = useTranslation();
  const {open} = useSearchBox();
  return (
    <NavBarItem>
      <SearchIcon
        onClick={open}
        className="w-6"
        title={t(translations.nav.search)}
      />
    </NavBarItem>
  );
}
