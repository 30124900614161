import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";

const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  
  export const DayOfTheWeek = ({ dayOfTheWeek }: { dayOfTheWeek: number }) => {
    const { t } = useTranslation();
    let dayOfTheWeekKey = weekDays[dayOfTheWeek];
    return <>{t(translations.dayOfTheWeek[dayOfTheWeekKey])}</>;
  };