import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { ReactComponent as CloseIcon } from "assets/close_circled_icon.svg";
import { Children } from "utils/reactUtils";

export default function CloseBox({
  onClick,
  children,
}: { onClick: () => void } & Children) {
  const { t } = useTranslation();
  return (
    <div className="absolute cursor-pointer top-2 right-2 text-right content-end flex items-center">
      {children}
        <CloseIcon
          className="text-slate-500 ml-2 hover:text-slate-800"
          title={t(translations.filter.closeLabel)}
          onClick={onClick}
        />
    </div>
  );
}
