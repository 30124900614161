export type Category = {
  id: number;
  name: string;
  unit_id?: number;
  unit_name?: string;
};

export const Categories = {
  Feeding: 1,
  Walking: 2,
  Exercise: 3,
  Weight: 4,
  Sugar: 5,
  Test: 6,
  Hospital: 7,
  Appointment: 8,
  Other: 10,
  Temperature: 11,
  Saturation: 12,
  Pulse: 14,
  Vaccine: 15,
  SolidFood: 16,
};

export const Units = {
  Ml: 1,
  Gr: 2,
};

export const CategoriesDetails = {
  [Categories.Feeding]: {
    id: Categories.Feeding,
    name: "Karmienie",
  },
}

export const getCategoryByID = (id: number): string => {
  const name = Object.entries(Categories)
    .filter((c) => c[1] === id)
    .map((c) => c[0])
    .pop();
  return name ?? "";
};

export const isCountAndUnitDisplayed = (category: number): boolean => {
  return category === Categories.Feeding || category === Categories.Weight || category === Categories.Exercise || category === Categories.Sugar;
};