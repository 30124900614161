import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useGetCategoriesQuery } from "services/categories";

export const SEARCHABLE_FIELDS = ["from", "to", "categoryId", "name"];

const SEARCH_BOX_TAG = "sb";
const SEARCH_BOX_TAG_VALUE = "1";

// Resolves the value of the search key param
type Resolver = (key: string) => string;

const useCategoryResolver = (): Resolver => {
  // Resolves the value of the category ID
  const [search] = useSearchParams();
  const { data: categories } = useGetCategoriesQuery();
  const resolver: Resolver = (key: string): string =>
    categories
      ?.filter((c) => c.id === parseInt(search.get(key) ?? ""))
      .map((c) => c.name)
      .join(" ") ?? "";
  return resolver;
};

const useCommonResolver = (): Resolver => {
  // Resolves the value of the search key
  const [search] = useSearchParams();
  const resolver: Resolver = (key: string): string => search.get(key) ?? "";
  return resolver;
};

export const useSearchParamsResolver = () => {
  // Creates a factory
  const categoryResolver = useCategoryResolver();
  const defaultResolver: Resolver = useCommonResolver();
  const resolvers = {
    categoryId: categoryResolver,
  };
  const multipleResolverProxy: Resolver = (key: string): string => {
    if (key in resolvers) {
      return resolvers[key](key);
    }
    return defaultResolver(key);
  };
  return { resolver: multipleResolverProxy };
};

export const useSearchParamsInfo = () => {
  const [search] = useSearchParams();
  const isAnyFilterDefined = () => {
    return SEARCHABLE_FIELDS.some((f) => search.has(f));
  };
  return [isAnyFilterDefined];
};

export const useSearchBox = () => {
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchBoxToggle = (open: boolean) => {
    if (open) {
      if (location.pathname !== "/events") {
        navigate("/events");
      } else {
        if (!search.has(SEARCH_BOX_TAG)) {
          search.append(SEARCH_BOX_TAG, SEARCH_BOX_TAG_VALUE);
          setSearch(search);
        }
      }
    } else {
      setSearch({});
    }
  };
  return {
    open: () => searchBoxToggle(true),
    hide: () => searchBoxToggle(false),
    isOpened: (): boolean =>
      search.has(SEARCH_BOX_TAG) &&
      search.get(SEARCH_BOX_TAG) === SEARCH_BOX_TAG_VALUE,
  };
};
