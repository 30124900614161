import MainLayout from "../layouts/MainLayout";
import { EditEvent } from "features/event/EditEvent";

export function NewEventPage() {
  return (
    <MainLayout>
      <EditEvent></EditEvent>
    </MainLayout>
  );
}
