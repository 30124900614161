import React, { ChangeEvent, FormEvent, useState } from "react";
import { formatDateStr, getDateNow } from "utils/date";

import { FormBox } from "components/Form";
import CenterContainer from "components/common/CenterContainer";
import PopupDialog from "components/dialog/PopupDialog";
import { translations } from "locales/translations";
import type { Event } from "models/Event";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetCategoriesQuery } from "services/categories";
import {
  useAddEventMutation,
  useDeleteEventMutation,
  useUpdateEventMutation,
} from "services/events";
import { EditEventCard } from "./EditEventCard";
import { CreateForm } from "./formCard/Factory";

type Props = {
  event?: Event;
};

const AlertMessage = ({ message }: { message: string }) => {
  return <div className="alert alert-danger">{message}</div>;
};

export function EditEvent({ event: originalEvent }: Props) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [event, setEvent] = useState<Event>(
    originalEvent ?? {
      id: -1,
      category_id: 1,
      name: "",
      description: "",
      price: 0,
      created_date: formatDateStr(getDateNow(), "datetime"),
    }
  );
  const navigate = useNavigate();
  const { data: categories, isLoading } = useGetCategoriesQuery();
  const [addEvent, { isLoading: isAddEventLoading }] = useAddEventMutation();
  const [deleteEvent, { isLoading: isDeleteEventLoading }] =
    useDeleteEventMutation();
  const [updateEvent, { isLoading: isUpdatingEventLoading }] =
    useUpdateEventMutation();
  const [showRemoveConfirmationDialog, setShowRemoveConfirmationDialog] =
    useState<boolean>();

  if (isLoading) {
    return <></>;
  }

  const saveChanges = async (event: Event, changeMethod) => {
    const result = await changeMethod(event).unwrap();
    if (result) {
      navigate("/events");
    } else {
      setErrorMessage(t(translations.editEvent.errorMessage));
    }
  };

  const onSaveChanges = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (event.id < 0) {
      saveChanges(event, addEvent);
    } else {
      saveChanges(event, updateEvent);
    }
  };

  const onValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEvent({
      ...event,
      [e.target.name]:
        e.target.type === "number" && e.target.value
          ? parseFloat(e.target.value)
          : e.target.value,
    });
  };

  const onCategoryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const categoryId = parseInt(e.target.value);
    const categoryName = e.target.options[e.target.selectedIndex].text;
    const category = categories?.find((c) => c.id === categoryId);
    const unitId = category?.unit_id;
    const unitName = category?.unit_name;

    setEvent({
      ...event,
      category_id: categoryId,
      category_name: categoryName,
      unit_id: unitId,
      unit_name: unitName,
    });
  };
  const title =
    event?.id < 0
      ? translations.editEvent.titleNewEvent
      : translations.editEvent.titleUpdateEvent;

  const onDeleteEvent = () => {
    setShowRemoveConfirmationDialog(true);
  };

  const removeEvent = async () => {
    setShowRemoveConfirmationDialog(false);
    const success = await deleteEvent(event.id).unwrap();

    if (success) {
      navigate("/events");
    } else {
      setErrorMessage("error");
    }
  };

  const cancelRemoveEvent = () => {
    setShowRemoveConfirmationDialog(false);
  };

  const hideButtons = showRemoveConfirmationDialog === true;

  const showSpinner =
    !hideButtons &&
    (isAddEventLoading || isUpdatingEventLoading || isDeleteEventLoading);

  const EventTypeEditComponent = CreateForm(event, onValueChange);

  const eventCardProps = {
    categoryId: event.category_id,
    categories: categories ?? [],
    createdDate: event.created_date,
    id: event.id,
    name: event.name,
    description: event.description,
    title: t(title),
    showSpinner,
    hideButtons,
    onCategoryChange,
    onValueChange,
    onDeleteEvent,
    EventTypeEditComponent,
  };
  
  return (
    <CenterContainer>
      {errorMessage ? <AlertMessage message={errorMessage} /> : null}
      <form onSubmit={onSaveChanges}>
        <FormBox>
          <EditEventCard {...eventCardProps} />
        </FormBox>
      </form>
      {showRemoveConfirmationDialog ? (
        <PopupDialog
          message={t(translations.editEvent.removeDialog.message)}
          confirmLabel={t(translations.editEvent.removeDialog.confirmLabel)}
          cancelLabel={t(translations.editEvent.removeDialog.cancelLabel)}
          onConfirmClicked={removeEvent}
          onCancelClicked={cancelRemoveEvent}
        />
      ) : (
        <></>
      )}
    </CenterContainer>
  );
}
