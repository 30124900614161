import CloseBox from "components/common/CloseBox";
import DailyEventBox from "./DailyEventBox";
import SearchItem from "./DailyEventsListSearchItem";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import {
  SEARCHABLE_FIELDS,
  useSearchParamsResolver,
  useSearchBox,
  useSearchParamsInfo,
} from "./Search";
import { ReactComponent as EditIcon } from "assets/pencil.svg";

export default function EventsListSearchInfo() {
  const { t } = useTranslation();
  const { resolver } = useSearchParamsResolver();
  const [search] = useSearchParams();
  const {open, hide} = useSearchBox();
  const [isAnyParamsPassed] = useSearchParamsInfo();

  if (!isAnyParamsPassed()) {
    return <></>;
  }

  const items = SEARCHABLE_FIELDS.filter((el) => search.has(el)).map((el) => (
    <span key={`si-${el}`} className="ml-2">
      <SearchItem key={`si-${el}`} name={el} getFilterValue={resolver} />
    </span>
  ));

  return (
    <DailyEventBox hoverEnabled={false}>
      <div className="relative -top-4 -right-4">
        <CloseBox onClick={hide}>
          <div
            key="edit"
            onClick={open}
            className="inline-block text-slate-600"
          >
            <EditIcon title={t(translations.searchInfo.editLabel)} />
          </div>
        </CloseBox>
      </div>
      <div className="">
        <p className="text-sm mb-2">
          {t(translations.searchInfo.searchingLabel)}
        </p>
        {items}
      </div>
    </DailyEventBox>
  );
}
