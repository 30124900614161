import DailyEventBox from "./DailyEventBox";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";

export const EmptyListInfo = () => {
  const { t } = useTranslation();
  return (
    <DailyEventBox hoverEnabled={false}>
      <div className="text-center">
        {t(translations.dailyEventList.noItemsFound)}
      </div>
    </DailyEventBox>
  );
};
