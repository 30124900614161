import { ReactComponent as LogoutIcon } from "./Logout.svg";
import { useLogoutMutation } from "services/auth";
import { NavBarItem } from "./NavBar";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { useNavigate } from "react-router-dom";

export default function NavLogOut() {
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const logOutUser = async () => {
    await logout({});
    navigate("/");
  };
  return (
    <NavBarItem>
      <LogoutIcon onClick={logOutUser} title={t(translations.nav.logout)} />
    </NavBarItem>
  );
}
