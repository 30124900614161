import { Children } from "utils/reactUtils";

export type OnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;

export type Props = Children & {
    onClick?: OnClick;
    href?: string; 
};

export const Link = ({onClick, href = "#", children}: Props) => {
    return <a href={href} className="underline text-teal-600 hover:no-underline hover:text-teal-900" onClick={onClick}>{children}</a>
}