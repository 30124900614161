export const FormUrlLink = ({ text, href }: { text: string; href: string }) => {
  return (
    <a
      className="inline-block align-baseline font-bold text-sm text-teal-500 hover:text-teal-800 p-4"
      href={href}
    >
      {text}
    </a>
  );
};
