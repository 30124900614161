import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import HomePage from "./pages/Home";
import { LoginPage } from "./pages/Login";

import "./locales/i18n";
import { NewEventPage } from "pages/NewEvent";
import PrivateRouter from "PrivateRouter";
import { RegisterPage } from "pages/Register";
import EventsPage from "pages/Events";

import EventPage from "pages/EventPage";
import { ProfilePage } from "pages/ProfilePage";
import ContactPage from "pages/ContactPage";
import { ActivateAccountPage } from "pages/Activate";
import ServerDocumentPage from "pages/ServerDocument";

const AnimatedRouter = () => {
  const location = useLocation();
  return (
    <Routes location={location}>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/activate" element={<ActivateAccountPage />} />
      <Route
        path="/privacy-policy"
        element={<ServerDocumentPage documentType="privacy_policy" />}
      />
      <Route
        path="/rodo"
        element={<ServerDocumentPage documentType="rodo" />}
      />
      <Route
        path="/regulatory"
        element={<ServerDocumentPage documentType="regulatory" />}
      />
      <Route path="/contact" element={<ContactPage />} />
      <Route
        path="/events/:id"
        element={
          <PrivateRouter>
            <EventPage></EventPage>
          </PrivateRouter>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRouter>
            <ProfilePage></ProfilePage>
          </PrivateRouter>
        }
      />
      <Route
        path="/events"
        element={
          <PrivateRouter>
            <EventsPage />
          </PrivateRouter>
        }
      />
      <Route
        path="/events/new"
        element={
          <PrivateRouter>
            <NewEventPage />
          </PrivateRouter>
        }
      />
    </Routes>
  );
};

export function App() {
  return (
    <BrowserRouter>
      <AnimatedRouter />
    </BrowserRouter>
  );
}

if (module.hot) {
  module.hot.accept(["./locales/i18n"], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

export default App;
