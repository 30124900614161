import { ChangeEvent } from "react";

export type OnChangeInputEvent = (event: ChangeEvent<HTMLInputElement>) => void;
export type OnChangeSelectEvent = (
  event: ChangeEvent<HTMLSelectElement>
) => void;
export type OnChangeTextAreaEvent = (
  event: ChangeEvent<HTMLTextAreaElement>
) => void;
export type BoxType = { children: React.ReactNode | React.ReactNode[] };

export const FormLabel = ({ text }: { text?: string }) => {
  return <span className="basis-1/4 p-2 dark:text-white">{text}</span>;
};

export const FormField = ({ children }: BoxType) => {
  return (
    <div className="flex items-center mb-4 bg-slate-200 dark:bg-slate-800 rounded-lg p-1">
      {children}
    </div>
  );
};

export const FormLabeledField = ({
  text,
  children,
}: { text: string } & BoxType) => {
  return (
    <FormField>
      <FormLabel text={text} />
      {children}
    </FormField>
  );
};

export const FormDate = ({
  name,
  date,
  onChange,
  type,
}: {
  name: string;
  date: string;
  type: "datetime-local" | "date";
  onChange: OnChangeInputEvent;
}) => {
  return (
    <input
      type={type}
      className="basis-3/4 p-3 rounded-lg dark:bg-slate-700"
      name={name}
      required
      value={date}
      onChange={onChange}
    />
  );
};

export const FormCount = ({
  name,
  value,
  onChange,
  step = "1",
  placeholder = "100",
  dataList = [],
}: {
  step?: string;
  placeholder?: string;
  name: string;
  value: number;
  onChange: OnChangeInputEvent;
  dataList?: Array<number>;
}) => {
  return (
    <>
      <input
        name={name}
        type="number"
        step={step}
        placeholder={placeholder}
        className="p-2 basis-3/4 rounded-lg dark:bg-slate-700 text-end"
        value={value}
        onChange={onChange}
        list={name + "_list"}
      />
      <datalist id={name + "_list"}>
        {dataList.map((data) => (
          <option value={data} key={data} />
        ))}
      </datalist>
    </>
  );
};

export const FormTitle = ({ text }: { text: string }) => {
  return <h4 className="text-lg font-bold uppercase mb-4">{text}</h4>;
};

export const FormName = ({
  name,
  text,
  onChange,
}: {
  name: string;
  text: string;
  onChange: OnChangeInputEvent;
}) => {
  return (
    <input
      name={name}
      type="text"
      className="p-2 basis-3/4 rounded-lg dark:bg-slate-700"
      value={text}
      onChange={onChange}
    />
  );
};

export const FormEmail = ({
  name,
  text,
  onChange,
}: {
  name: string;
  text: string;
  onChange: OnChangeInputEvent;
}) => {
  return (
    <input
      name={name}
      type="email"
      className="p-2 basis-3/4 rounded-lg dark:bg-slate-700"
      value={text}
      onChange={onChange}
    />
  );
};

export const FormDescription = ({
  name,
  text,
  onChange,
}: {
  name: string;
  text: string;
  onChange: OnChangeTextAreaEvent;
}) => {
  return (
    <textarea
      name={name}
      className="p-2 basis-3/4 rounded-lg dark:bg-slate-700"
      onChange={onChange}
      value={text}
    />
  );
};

export const FormBox = ({ children }: BoxType) => {
  return (
    <div className="box-content border-4 rounded-lg p-6 flex flex-col">
      {children}
    </div>
  );
};
