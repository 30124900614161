export function redirectHome() {
  window.location.href = "/";
}

export function reloadPage() {
  window.location.reload();
}

export function goBack(): void {
  window.history.back();
}
