import { ActivateAccountComponent } from "features/auth/Activate.React";
import MainLayout from "layouts/MainLayout";

export const ActivateAccountPage = () => {
  return (
    <MainLayout>
      <ActivateAccountComponent />
    </MainLayout>
  );
};
