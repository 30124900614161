import { CommonPage } from "./CommonPage";
import { Profile } from "features/profile/Profile";

export function ProfilePage() {
  return (
    <CommonPage>
      <Profile></Profile>
    </CommonPage>
  );
}
