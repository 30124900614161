import { ReactComponent as ProfileIcon } from "./profile.svg";

import { NavBarItem } from "./NavBar";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { useNavigate } from "react-router-dom";

export default function NavProfile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <NavBarItem>
      <ProfileIcon onClick={() => navigate("/profile")} title={t(translations.nav.profile)} />
    </NavBarItem>
  );
}
