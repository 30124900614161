import { setNavMode } from "features/nav/navSlice";
import { useAppDispatch } from "store";
import { WelcomeComponent } from "../components/Welcome";
import MainLayout from "../layouts/MainLayout";
import { useGetUserQuery } from "services/user";
import { useEffect } from "react";

export default function HomePage() {
  const { data: user } = useGetUserQuery();
  const dispatch = useAppDispatch();
  const navMode = user ? "login" : "invisible";

  useEffect(() => {
    dispatch(setNavMode(navMode));
  }, [navMode, dispatch]);

  return (
    <MainLayout>
      <WelcomeComponent />
    </MainLayout>
  );
}
