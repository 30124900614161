import { translations } from "locales/translations";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="p-4 bg-white shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
        © 2023{" "}
        <a href="https://guideme.bydgoszcz.pl/" className="hover:underline">
          GuideMe
        </a>
      </span>
      <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
          <Link to="/privacy-policy" className="mr-4 hover:underline md:mr-6">
            {t(translations.footer.privacyPolicy)}
          </Link>
        </li>
        <li>
          <Link to="/regulatory" className="mr-4 hover:underline md:mr-6">
            {t(translations.footer.regulatory)}
          </Link>
        </li>
        <li>
          <Link to="/rodo" className="mr-4 hover:underline md:mr-6">
            {t(translations.footer.rodo)}
          </Link>
        </li>
        <li>
          <Link to="/contact" className="hover:underline">
            {t(translations.footer.contact)}
          </Link>
        </li>
      </ul>
    </footer>
  );
}
