import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { Link } from "components/common/Link";
import { ElapsedTime } from "utils/date";
import { PropsWithChildren } from "react";

export const TimeElapsed = ({ elapsedTime }: { elapsedTime: ElapsedTime }) => {
  const { t } = useTranslation();
  return (
    <div className="flex pt-1 items-center">
      <label className="basis-5/6 text-xs">
        {elapsedTime.text} {t(translations.editEvent.lastEventAgoSuffix)}
      </label>
    </div>
  );
};

export const EventName = ({ name }: { name: string }) => {
  return (
    <div className="flex pt-1 items-center">
      <label>{name}</label>
    </div>
  );
};

export const CountAndUnit = ({
  count,
  unitName,
}: {
  count: number;
  unitName?: string;
}) => {
  return (
    <p className="text-lg flex-none text-right">
      {count} {unitName}
    </p>
  );
};

export const CategoryName = ({ categoryName }: { categoryName: string }) => {
  const { t } = useTranslation();
  return (
    <span className="ml-2 text-sm">
      {t(translations.categories[categoryName])}
    </span>
  );
};

export const CategoryIcon = ({ categoryName }: { categoryName: string }) => {
  const { t } = useTranslation();
  return (
    <label className="text-2xl flex w-8 justify-center items-center">
      {t(translations.categoryIcons[categoryName])}
    </label>
  );
};

export const DateTimeInfo = ({
  eventId,
  createdDate,
}: {
  createdDate: string;
  eventId: number;
}) => {
  const createdTime = createdDate.substring(11).substring(0, 5);
  return (
    <Link href={`/events/${eventId}/`}>
      <span className="text font-semibold">{createdTime}</span>
    </Link>
  );
};

export const CardBox = ({ children }: PropsWithChildren) => {
  return <div className={"p-3 bg-white dark:bg-slate-700"}>{children}</div>;
};
