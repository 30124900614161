import { EditEvent } from "features/event/EditEvent";
import { useParams } from "react-router-dom";
import { useGetEventQuery } from "services/events";
import { ErrorPage } from "./ErrorPage";
import { CommonPage } from "./CommonPage";
import { LoadingBox } from "components/common/LoadingBox";

const parseEventId = (id?: string | undefined): number | null => {
  if (id === undefined) {
    return null;
  }

  let idNumber = 0;
  try {
    idNumber = parseInt(id);
  } catch {}

  return idNumber > 0 ? idNumber : null;
};

const EventPageInner = ({ eventId }: { eventId: number }) => {
  const { data: event, isLoading, isFetching } = useGetEventQuery(eventId);
  if (isLoading || isFetching) {
    return (
      <CommonPage>
        <LoadingBox/>
      </CommonPage>
    );
  }
  if (!event) {
    return <ErrorPage message="Event cannot be loaded properly" />;
  }
  return (
    <CommonPage>
      <EditEvent event={event} />
    </CommonPage>
  );
};

export default function EventPage() {
  const { id } = useParams();
  const eventId = parseEventId(id);

  if (eventId === null) {
    return <ErrorPage message="Incorrectly passed Event ID" />;
  }

  return <EventPageInner eventId={eventId} />;
}
