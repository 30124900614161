import { translations } from "locales/translations";
import { useTranslation } from "react-i18next";
import { NavBarItem } from "./NavBar";
import { useGetUserQuery } from "services/user";
import { useNavigate } from "react-router-dom";

export default function NavTitle() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {data: currentUser, isLoading } = useGetUserQuery();
  const title = isLoading ? "" : currentUser?.name ? currentUser.name : t(translations.app.name);
  return (
    <NavBarItem className="flex-grow">
      <label
        className="whitespace-nowrap font-bold uppercase cursor-pointer"
        onClick={() => navigate('/')}
      >
        {title}
      </label>
    </NavBarItem>
  );
}
