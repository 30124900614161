import { useEffect, useRef } from "react";
import { GS_RE_CAPTCHA_APP_ID } from "./ReCaptcha";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";

export const ReCaptcha = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    if (ref.current.innerHTML !== null) {
      return;
    }
    // @ts-ignore
    grecaptcha.enterprise.render(ref.current, {
      sitekey: GS_RE_CAPTCHA_APP_ID,
    });
  }, [ref]);

  return (
    <>
      <p className="text-slate-500 text-sm max-w-sm dark:text-slate-300">
        {t(translations.reCaptcha.message)}
        <a
          href="https://policies.google.com/privacy"
          className="underline hover:text-teal-700"
        >
          {t(translations.reCaptcha.privacyPolicy)}
        </a>
        {t(translations.reCaptcha.afterPrivacyPolicy)}
        <a
          href="https://policies.google.com/terms"
          className="underline hover:text-teal-700"
        >
          {t(translations.reCaptcha.termsOfService)}
        </a>
        {t(translations.reCaptcha.afterTermsOfService)}
      </p>
      <div className="g-recaptcha" ref={ref}></div>
    </>
  );
};
