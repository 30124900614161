import type { EventAdapter } from "models/Event";
import { DefaultEventCard } from "./card/DefaultEventCard";
import { isCountAndUnitDisplayed } from "models/Category";

export const EventCard = ({
  event,
  isTodayEvent,
}: {
  event: EventAdapter;
  isTodayEvent: boolean;
}) => {
  return (
    <DefaultEventCard event={event} isTodayEvent={isTodayEvent} displayCountAndUnit={isCountAndUnitDisplayed(event.category_id)}/>
  );
};
