import { ButtonPrimary } from "components/common/Buttons";
import CenterContainer from "components/common/CenterContainer";
import {
  FormBox,
  FormTitle,
  FormField,
  FormLabel,
  FormName,
  FormEmail,
  FormDescription,
} from "components/Form";
import { translations } from "locales/translations";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { getHtmlObjValue } from "utils/obj";

type Contact = {
  name: string;
  email: string;
  title: string;
  description: string;
};

export default function ContactForm() {
  const { t } = useTranslation();
  const [contact, setContact] = useState<Contact>({
    name: "",
    email: "",
    title: "",
    description: "",
  });
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  const onValueChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const newContact = { ...contact };
    newContact[e.target.name] = getHtmlObjValue(e);
    setContact(newContact);
  };

  return (
    <CenterContainer>
      <form onSubmit={onSubmit}>
        <FormBox>
          <FormTitle text={t(translations.contactForm.title)} />
          <FormField>
            <FormLabel text={t(translations.contactForm.nameLabel)} />
            <FormName
              name="name"
              text={contact.name}
              onChange={onValueChange}
            />
          </FormField>
          <FormField>
            <FormLabel text={t(translations.contactForm.emailLabel)} />
            <FormEmail
              name="email"
              text={contact.email}
              onChange={onValueChange}
            />
          </FormField>
          <FormField>
            <FormLabel text={t(translations.contactForm.titleLabel)} />
            <FormName
              name="title"
              text={contact.title}
              onChange={onValueChange}
            />
          </FormField>
          <FormField>
            <FormLabel text={t(translations.contactForm.descriptionLabel)} />
            <FormDescription
              name="description"
              text={contact.description}
              onChange={onValueChange}
            />
          </FormField>
          <ButtonPrimary type="submit">
            {t(translations.contactForm.submitButton)}
          </ButtonPrimary>
        </FormBox>
      </form>
    </CenterContainer>
  );
}
