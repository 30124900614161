import { OnChangeSelectEvent } from "components/Form";
import { Gender } from "models/models";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";

const ITEM_NAME = "gender";

const FormOption = ({ value, text }: { value: Gender, text: string }) => {
    return <option key={`${ITEM_NAME}-${value}`} value={value}>
        {text}
    </option>;
};

type Props = { selectedId: string, items: Gender[], onChange: OnChangeSelectEvent };

export default function GenderDropDown({ selectedId, items, onChange }: Props) {
    const { t } = useTranslation();
    return <select
        name={`${ITEM_NAME}`}
        className="p-2 basis-1/2 rounded-lg dark:bg-slate-700"
        value={selectedId}
        onChange={onChange}
    >
        <option key={`${ITEM_NAME}-undefined`} value="">{t(translations.profile.gender.undefined)}</option>
        {items.map(item => <FormOption key={`${ITEM_NAME}-${item}`} text={t(translations.profile.gender[item])} value={item} />)}
    </select>;
};