import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";


const slice = createSlice({
    name: "events",
    initialState: { fromDate: "", toDate: "", category: "" } ,
    reducers: {
      setFromDate: (
        state,
        {
          payload: fromDate,
        }: PayloadAction<string>
      ) => {
        state.fromDate = fromDate;
      },
      setToDate: (
        state,
        {
          payload: toDate,
        }: PayloadAction<string>
      ) => {
        state.toDate = toDate;
      },
      setCategory: (
        state,
        {
          payload: category,
        }: PayloadAction<string>
      ) => {
        state.category = category;
      },
    },
});

export const { setFromDate, setToDate, setCategory  } = slice.actions;
  
export default slice.reducer;

export const selectFromDate = (state: RootState) => state.events.fromDate;
export const selectToDate = (state: RootState) => state.events.toDate;
export const selectCategory = (state: RootState) => state.events.category;
