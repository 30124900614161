export type OnButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

interface Props {
  onClick?: OnButtonClick;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean | undefined,
};

export const ButtonBase = ({
  children,
  onClick,
  className,
  type = "button",
  disabled,
}: Props) => (
  <button
    className={
      className +
      " inline-block px-6 py-2.5 font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
    }
    disabled={disabled}
    type={type}
    onClick={onClick}
  >
    {children}
  </button>
);

export const ButtonPrimary = ({ children, onClick, className }: Props) => (
  <button
    className={
      className +
      " inline-block px-6 py-2.5 bg-teal-700 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-teal-600 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-teal-500 active:shadow-lg transition duration-150 ease-in-out"
    }
    onClick={onClick}
  >
    {children}
  </button>
);

export const ButtonSecondary = ({ children, onClick, className }: Props) => (
  <button
    onClick={onClick}
    className={
      className +
      " inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
    }
  >
    {children}
  </button>
);

export const ButtonTertiary = ({ children, onClick, className }: Props) => (
  <button
    onClick={onClick}
    className={
      className +
      " inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
    }
  >
    {children}
  </button>
);

export const ButtonQuaternary = ({ children, onClick, className }: Props) => (
  <button
    onClick={onClick}
    className={
      className +
      " inline-block px-6 py-2.5 bg-white-600 text-black dark:text-slate-200 dark:bg-slate-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-white-700 hover:shadow-lg focus:bg-white-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-white-800 active:shadow-lg transition duration-150 ease-in-out"
    }
  >
    {children}
  </button>
);
