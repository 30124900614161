import { useGetDailyEventsQuery } from "services/events";
import { DailyEvents } from "models/Event";
import { useSearchParams } from "react-router-dom";
import { createObj } from "utils/obj";
import EventsListSearchInfo from "./DailyEventsListSearchInfo";
import { useSearchParamsInfo } from "./Search";
import { LoadingBox } from "components/common/LoadingBox";
import { DailyEventsCardList } from "./DailyEventsCardList";
import { EmptyListInfo } from "./EmptyListInfo";
import { AddFirstEvent } from "./AddFirstEvent";

export default function DailyEventsList() {
  const [search] = useSearchParams();
  const [isAnyParamsPassed] = useSearchParamsInfo();
  const categoryId = search.has("categoryId")
    ? parseInt(search.get("categoryId") ?? "")
    : null;
  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetDailyEventsQuery(
    createObj({
      from: search.get("from"),
      to: search.get("to"),
      categoryId: categoryId,
      name: search.get("name"),
    })
  );
  if (isLoading || isFetching) {
    return <LoadingBox />;
  }
  const dailyEvents = data.map((d) => new DailyEvents(d.events, d.date));
  const emptyListInfoVisible = dailyEvents?.length === 0 && isAnyParamsPassed();
  const addFirstEventVisible =
    dailyEvents?.length === 0 && !isAnyParamsPassed();
  const dailyEventsVisible = dailyEvents?.length > 0;

  return (
    <>
      <EventsListSearchInfo />
      {emptyListInfoVisible ? <EmptyListInfo /> : null}
      {addFirstEventVisible ? <AddFirstEvent /> : null}
      {dailyEventsVisible ? (
        <DailyEventsCardList dailyEvents={dailyEvents} />
      ) : null}
    </>
  );
}
