import NavSigned from "./NavSigned";
import NavUnsigned from "./NavUnsigned";
import { NavBar } from "./NavBar";
import { selectNavMode } from "./navSlice";
import {useAppSelector} from "store";
import { useGetUserQuery } from "services/user";

export const NavComponent = () => {
  const { data: user } = useGetUserQuery();
  const navMode = useAppSelector(selectNavMode);

  if (navMode === "invisible") {
    return <NavBar/>;
  }
  if (user) {
    return <NavSigned />;
  } else {
    return <NavUnsigned />;
  }
};
