import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import ItemCard from "components/common/ItemCard";
import { useLoginMutation } from "services/auth";
import { ButtonPrimary } from "components/common/Buttons";
import CenterContainer from "components/common/CenterContainer";
import { FormInput } from "./FormInput";
import FormButtonsBar from "./FormButtonsBar";
import { FormUrlLink } from "./FormLink";
import { useNavigate } from "react-router-dom";
import { LoadingToast, useToast, WarningToast } from "components/Toast";
import { useReCaptcha } from "gs/ReCaptcha";
import { ReCaptcha } from "gs/ReCaptcha.React";
import "./ReCaptcha.css";

export default function LoginEmail() {
  const { t } = useTranslation();
  const { getToken } = useReCaptcha();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [login] = useLoginMutation();
  const navigate = useNavigate();
  const [loadingToast, setLoadingToast] = useToast();
  const [warningToast, setWarningToast] = useToast();

  const onSubmitFormWithToken = async (token: string) => {
    console.log("Submit form with token '%s'", token);
    const loginResponse = await login({
      email: emailRef?.current?.value,
      password: passwordRef?.current?.value,
      token,
    }).unwrap();
    setLoadingToast({
      visible: false,
    });

    if (loginResponse.user?.id) {
      navigate("/");
    } else {
      setWarningToast({
        text: t(translations.loginPage.incorrectLoginData),
        title: t(translations.loginPage.incorrectLoginDataTitle),
        visible: true,
      });
    }
  };
  const onSubmitForm = async (evt?: React.FormEvent<HTMLFormElement>) => {
    if (evt !== undefined && evt.preventDefault) {
      evt.preventDefault();
    }
    setWarningToast({
      visible: false,
    });
    setLoadingToast({
      visible: true,
    });
    try {
      if (window.location.hostname.includes("localhost")) {
        await onSubmitFormWithToken("");
      } else {
        await getToken(onSubmitFormWithToken);
      }
    } catch (e) {
      setWarningToast({
        visible: true,
      });
    } finally {
      setLoadingToast({
        visible: false,
      });
    }
  };
  return (
    <CenterContainer>
      <div className="mb-2">
        <LoadingToast title="Loading" visible={loadingToast.visible}>
          {t(translations.loginPage.loadingPleaseWait)}
        </LoadingToast>
        <WarningToast
          title={t(translations.loginPage.incorrectLoginDataTitle)}
          visible={warningToast.visible}
          onClose={() => setWarningToast({ visible: false })}
        >
          {warningToast.text}
        </WarningToast>
      </div>
      <ItemCard>
        <form onSubmit={onSubmitForm}>
          <FormInput
            ref={emailRef}
            title={t(translations.loginPage.username)}
            placeholder={t(translations.loginPage.username)}
            type="email"
            name="email"
          />
          <FormInput
            ref={passwordRef}
            name="password"
            type="password"
            placeholder="***********"
            title={t(translations.loginPage.password)}
          />
          <ReCaptcha />
          <FormButtonsBar>
            <ButtonPrimary type="submit">
              {t(translations.loginPage.signIn)}
            </ButtonPrimary>
            <FormUrlLink
              text={t(translations.loginPage.forgotPassword)}
              href={"/forgotPassword"}
            />
          </FormButtonsBar>
        </form>
      </ItemCard>
    </CenterContainer>
  );
}
