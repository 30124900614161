import { Categories } from "models/Category";
import type { Event } from "models/Event";
import { ChangeEvent } from "react";
import { translations } from "locales/translations";
import { LabeledCountCardFloatingStepForm } from "./common/LabeledCountCardFloatingStepForm";

export function CreateForm(
  event: Event,
  onValueChange: (e: ChangeEvent<HTMLInputElement>) => void
): React.ReactElement {
  switch (event.category_id) {
    case Categories.Feeding:
      return (
        <LabeledCountCardFloatingStepForm
          event={event}
          onValueChange={onValueChange}
          includeUnitName={true}
          titleKey={translations.editEvent.countLabel}
          dataList={[100, 120, 130, 140, 150, 160, 170, 180, 190, 200]}
        />
      );
    case Categories.Weight:
      return (
        <LabeledCountCardFloatingStepForm
          event={event}
          onValueChange={onValueChange}
          includeUnitName={true}
          titleKey={translations.editEvent.weightLabel}
        />
      );
    default:
      return <></>;
  }
}
