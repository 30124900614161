import { useRef, useState } from "react";
import { useRegisterMutation } from "services/auth";
import CenterContainer from "components/common/CenterContainer";
import ItemCard from "components/common/ItemCard";
import { FormInput } from "./FormInput";
import FormButtonsBar from "./FormButtonsBar";
import { ButtonPrimary, ButtonQuaternary } from "components/common/Buttons";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { FormHeader } from "./FormHeader";
import { useNavigate } from "react-router-dom";
import { useReCaptcha, APP_URL } from "gs/ReCaptcha";
import { ReCaptcha } from "gs/ReCaptcha.React";
import "./ReCaptcha.css";
import { ErrorToast, SuccessToast, useToast } from "components/Toast";

const RegisterCardForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const { t, i18n } = useTranslation();
  const { getToken } = useReCaptcha();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const [register, { isError }] = useRegisterMutation();
  const [successToast, setSuccessToast] = useToast();
  const [errorToast, setErrorToast] = useToast();
  const navigate = useNavigate();

  const onRegisterWithToken = async (token: string) => {
    const result = await register({
      email: emailRef?.current?.value,
      password: passwordRef?.current?.value,
      password_confirmation: confirmPasswordRef?.current?.value,
      token,
      locale: i18n.language,
    }).unwrap();

    if (!result.result || isError) {
      if (result.message) {
        setErrorToast({
          visible: true,
          text: t(translations.register.errors[result.message]),
        });
      } else {
        setErrorToast({
          visible: true,
          text: t(translations.register.incorrectCredentials),
        });
      }
    } else {
      setSuccessToast({
        visible: true,
      });
      onSuccess();
    }
  };
  const onRegisterClicked = async (evt?: React.FormEvent<HTMLFormElement>) => {
    if (evt !== undefined && evt.preventDefault) {
      evt.preventDefault();
    }
    if (passwordRef?.current?.value !== confirmPasswordRef?.current?.value) {
      setErrorToast({
        visible: true,
        text: t(translations.register.passwordsMismatchError),
      });
      return;
    }
    if (window.location.hostname.includes(APP_URL)) {
      await getToken(onRegisterWithToken);
    } else {
      await onRegisterWithToken("");
    }
  };

  return (
    <form onSubmit={onRegisterClicked}>
      <SuccessToast visible={successToast.visible}>
        {t(translations.register.successfulLoginMessage)}
      </SuccessToast>
      <ErrorToast visible={errorToast.visible}>{errorToast.text}</ErrorToast>
      <FormHeader>{t(translations.register.header)}</FormHeader>
      <FormInput
        name="email"
        type="email"
        placeholder="sample@website.com"
        ref={emailRef}
        title={t(translations.register.emailAddress)}
      />

      <FormInput
        type="password"
        placeholder="**********"
        title={t(translations.register.password)}
        name="password"
        ref={passwordRef}
      />

      <FormInput
        type="password"
        placeholder="**********"
        title={t(translations.register.confirmPassword)}
        name="password_confirmation"
        ref={confirmPasswordRef}
      />
      <ReCaptcha />
      <div className="mt-4">
        <FormButtonsBar>
          <ButtonPrimary type="submit">
            {t(translations.register.registerButton)}
          </ButtonPrimary>
          <ButtonQuaternary onClick={() => navigate("/login")}>
            {t(translations.register.cancelButton)}
          </ButtonQuaternary>
        </FormButtonsBar>
      </div>
    </form>
  );
};

const RegisterLinkSendInformation = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="text-lg uppercase mt-4 mb-8 font-semibold">
        {t(translations.register.linkSendInformationHeader)}
      </h1>
      <p>{t(translations.register.linkSendInformation)}</p>
      <p className="mt-4">{t(translations.register.linkSendInformation2)}</p>
    </>
  );
};

export function RegisterComponent() {
  const [success, setSuccess] = useState<boolean>(false);
  return (
    <CenterContainer>
      <ItemCard>
        {success ? (
          <RegisterLinkSendInformation />
        ) : (
          <RegisterCardForm onSuccess={() => setSuccess(true)} />
        )}
      </ItemCard>
    </CenterContainer>
  );
}
