import ContactForm from "features/docs/ContactForm";
import { CommonPage } from "./CommonPage";

export default function ContactPage() {
  return (
    <CommonPage>
      <ContactForm />
    </CommonPage>
  );
}
