import type { DailyEvents } from "models/Event";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { useState, useEffect } from "react";
import { ReactComponent as ArrowDown } from "assets/arrow_down.svg";
import DailyEventBox from "./DailyEventBox";
import { useSearchParamsInfo } from "./Search";
import { EventCardList } from "./EventCardList";
import { DayOfTheWeek } from "./DayOfTheWeek";

export const DailyEventsCard = ({
  dailyEvents,
}: {
  dailyEvents: DailyEvents;
}) => {
  const { t } = useTranslation();
  const [isAnyParamsPassed] = useSearchParamsInfo();
  const [expanded, setExpanded] = useState<boolean>(
    dailyEvents.isToday() || isAnyParamsPassed()
  );
  const [elapsedTime, setElapsedTime] = useState<string>();
  const dayOfTheWeek = dailyEvents.getDayOfTheWeek();
  const dayColor = dailyEvents.isToday() ? "bg-teal-500" : "bg-slate-300";

  useEffect(() => {
    if (isAnyParamsPassed() && dailyEvents.events.length === 0) {
      return;
    }
    setElapsedTime(dailyEvents.getFeedingElapsedTime());
    if (dailyEvents.isToday()) {
      const interval = setInterval(() => {
        setElapsedTime(dailyEvents.getFeedingElapsedTime());
      }, 10 * 1000);
      return () => clearInterval(interval);
    }
    return () => null;
  }, [dailyEvents, isAnyParamsPassed]);

  if (dailyEvents.events.length === 0) {
    return <></>;
  }

  const roundedStyle = expanded ? "rounded-t-lg" : "rounded-lg";

  return (
    <DailyEventBox hoverEnabled={true}>
      <div className="flex">
        <label className="self-center mr-4 basis-3/6">
          <span
            className={`text-xl ${dayColor} dark:bg-slate-600 rounded-lg p-1`}
          >
            {dailyEvents.getDay()}/{dailyEvents.getMonth()}
          </span>
          <span className="ml-2">
            <DayOfTheWeek dayOfTheWeek={dayOfTheWeek} />
          </span>
        </label>
        <label className="ml-4 text-2xl basis-3/6 text-right font-semibold text-teal-700 dark:text-teal-600">
          {dailyEvents.getTotalFeedingAmount()} ml
        </label>
      </div>
      {dailyEvents.isToday() && (
        <div className="mt-4 flex">
          <label className="">
            {t(translations.editEvent.lastFeed)}
            <span className="font-semibold">
              {elapsedTime} {t(translations.editEvent.lastEventAgoSuffix)}
            </span>
          </label>
        </div>
      )}
      {!isAnyParamsPassed() && (
        <div
          className={`flex mt-4 border-slate-100 dark:border-slate-600 border-2 bg-slate-200 ${roundedStyle} p-1 pl-3 pr-3 dark:bg-slate-700 cursor-pointer`}
          onClick={() => setExpanded(!expanded)}
        >
          <label className="basis-5/6 cursor-pointer">
            {t(translations.editEvent.feedCount)}
            {dailyEvents.getFeedingCount()}
          </label>
          <label className="flex flex-row-reverse basis-1/6 cursor-pointer self-center text-slate-800 dark:text-slate-300">
            {expanded ? (
              <ArrowDown className="h-4 rotate-180" />
            ) : (
              <ArrowDown className="h-4" />
            )}
          </label>
        </div>
      )}

      <div>
        {expanded && (
          <div>
            <EventCardList
              events={dailyEvents.events}
              isToday={dailyEvents.isToday()}
            />
          </div>
        )}
      </div>
    </DailyEventBox>
  );
};
