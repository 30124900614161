import DailyEventBox from "./DailyEventBox";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";

export const AddFirstEvent = () => {
  const { t } = useTranslation();
  return (
    <DailyEventBox hoverEnabled={false}>
      <p>{t(translations.dailyEventList.addFirstEvent)}</p>
    </DailyEventBox>
  );
};
