import { User } from "models/models";
import { api } from "./api";
import qs from "qs";
import { ChangeResult } from "./utils";

const userApiTagged = api.enhanceEndpoints({ addTagTypes: ["User"] });
export const userApi = userApiTagged.injectEndpoints({
  endpoints: (build) => ({
    updateUser: build.mutation<ChangeResult, any>({
      query: (user: User) => ({
        url: "api/users/current",
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: qs.stringify(user),
      }),
      invalidatesTags: ["User"],
    }),
    getUser: build.query<User, void>({
      query: () => `api/users/current`,
      providesTags: ["User"],
    }),
  }),
});

export const { useUpdateUserMutation, useGetUserQuery } = userApi;

export const {
  endpoints: { updateUser, getUser },
} = userApi;
