import { LoadingBox } from "components/common/LoadingBox";
import { CommonPage } from "pages/CommonPage";
import { Navigate } from "react-router-dom";
import { useGetUserQuery } from "services/user";

export default function PrivateRouter({
  children,
}: {
  children: React.ReactNode;
}) {
  const { data: user, isLoading, isFetching } = useGetUserQuery();
  if (isFetching || isLoading) {
    return (
      <CommonPage>
        <LoadingBox />
      </CommonPage>
    );
  }
  return user ? (
    <>{children}</>
  ) : (
    <Navigate
      replace={true}
      to="/"
      state={{ from: `${window.location.pathname}${window.location.search}` }}
    />
  );
}
