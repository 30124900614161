import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { userApi } from "services/user";

type NavMode = "logout" | "login" | "invisible";
type NavState = {
    navMode: NavMode;
};

const slice = createSlice({
    name: "nav",
    initialState: { navVisible: true, navMode: "logout" } as NavState,
    reducers: {
      setNavMode: (
        state,
        {
          payload: navMode,
        }: PayloadAction<NavMode>
      ) => {
        state.navMode = navMode;
      },
    },
    extraReducers: (builder) => {
      builder.addMatcher(
        userApi.endpoints.getUser.matchFulfilled,
        (state, { payload: user }) => {
          if (user) {              
            state.navMode = "login";
          }
        }
      );
    }
  });
  
  export const { setNavMode  } = slice.actions;
  
  export default slice.reducer;
  
  export const selectNavMode = (state: RootState) => state.nav.navMode;
  