import ItemCard from "components/common/ItemCard";
import { FormDate, FormField, FormLabel, FormName } from "components/Form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormHeader } from "features/auth/FormHeader";
import FormCategory from "./FormCategory";
import { useGetCategoriesQuery } from "services/categories";
import { useState } from "react";
import { formatDateStr, getDateNow, getDateStr } from "utils/date";
import { ButtonPrimary, ButtonQuaternary } from "components/common/Buttons";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { SEARCHABLE_FIELDS } from "./Search";
import { isEmpty } from "utils/obj";

export type Filter = {
  categoryId: number | null;
  from: string;
  to: string;
  name: string | null;
};
const DEFAULT_TIME_RANGE_DAYS = 10;
const INITIAL_FILTER = {
  from: formatDateStr(getDateStr(DEFAULT_TIME_RANGE_DAYS), "date"),
  to: formatDateStr(getDateNow(), "date"),
  categoryId: -1,
  name: null,
};

const useDefaultFilter = () => {
  const [search] = useSearchParams();
  const filter = INITIAL_FILTER;
  SEARCHABLE_FIELDS.forEach((field) => {
    if (search.has(field)) {
      filter[field] = search.get(field);
    }
  });
  return filter;
};

export const EventsFilter = () => {
  const [, setSearch] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const defaultFilter = useDefaultFilter();
  const { data: categories } = useGetCategoriesQuery();
  const [filter, setFilter] = useState<Filter>(defaultFilter);
  const onValueChanged = (name: string, value: string | number) => {
    const newFilter = {
      ...filter,
    };
    newFilter[name] = value;
    setFilter(newFilter);
  };
  const callSearch = () => {
    const newSearch = {};
    const newFilter = { ...filter };
    if (newFilter.categoryId === -1) {
      newFilter.categoryId = null;
    }
    if (isEmpty(newFilter.name)) {
      newFilter.name = null;
    }
    Object.entries(newFilter)
      .filter(([k, v]) => v !== null)
      .forEach(([k, v]) => (newSearch[k] = v));
    setSearch(newSearch);
  };

  return (
    <>
      <ItemCard>
        <FormHeader>{t(translations.filter.header)}</FormHeader>
        <p className="mb-4">{t(translations.filter.description)}</p>
        <FormField key={"dateFrom"}>
          <FormLabel text={t(translations.filter.fromDateLabel)} />
          <FormDate
            name={"from"}
            date={filter.from}
            onChange={(e) => onValueChanged(e.target.name, e.target.value)}
            type="date"
          />
        </FormField>
        <FormField key={"dateTo"}>
          <FormLabel text={t(translations.filter.toDateLabel)} />
          <FormDate
            name={"to"}
            date={filter.to}
            onChange={(e) => onValueChanged(e.target.name, e.target.value)}
            type="date"
          />
        </FormField>
        <FormField key={"type"}>
          <FormLabel text={t(translations.filter.categoryLabel)} />
          <FormCategory
            categories={categories ?? []}
            onChange={(e) => onValueChanged("categoryId", e.target.value)}
            key="category"
            selectedCategoryId={filter.categoryId ?? -1}
          />
        </FormField>
        <FormField key={"name"}>
          <FormLabel text={t(translations.filter.nameLabel)} />
          <FormName
            name={"name"}
            text={filter.name ?? ""}
            onChange={(e) => onValueChanged(e.target.name, e.target.value)}
          />
        </FormField>
        <ButtonPrimary type="button" className="w-full" onClick={callSearch}>
          {t(translations.filter.searchLabel)}
        </ButtonPrimary>
        <ButtonQuaternary
          type="button"
          className="w-full mt-4"
          onClick={() => navigate("/events")}
        >
          {t(translations.filter.cleanLabel)}
        </ButtonQuaternary>
      </ItemCard>
    </>
  );
};
