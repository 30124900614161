import { EventAdapter } from "models/Event";
import { EventCard } from "./EventCard";

export const EventCardList = ({
  events,
  isToday,
}: {
  events: EventAdapter[];
  isToday: boolean;
}) => {
  return (
    <>
      {events.map((e) => (
        <EventCard key={`event-${e.id}`} isTodayEvent={isToday} event={e} />
      ))}
    </>
  );
};
