import DailyEventsList from "features/event/DailyEventsList";
import { EventsFilter } from "features/event/EventsFilter";
import MainLayout from "layouts/MainLayout";
import { useSearchBox } from 'features/event/Search';

export default function EventsPage() {
  const { isOpened: isSearchBoxOpened } = useSearchBox(); 
  return <MainLayout>
    <div className="flex flex-col items-center sm:m-auto md:m-auto lg:m-auto xl:m-auto xxl:m-auto">
      { isSearchBoxOpened() ? <EventsFilter/> : null}
      <DailyEventsList />
    </div>
  </MainLayout>
};
