export const GS_RE_CAPTCHA_APP_ID = "6LcW-U8jAAAAABm7JHf4ZZFqAhYbob7nw_4UY6sr";
export const APP_URL = "bobas.guideme.bydgoszcz.pl";

export const useReCaptcha = () => {
  const getToken = async (
    onTokenRetrieved: (token: string) => Promise<void>
  ) => {
    // @ts-ignore
    grecaptcha.enterprise.ready(() => {
      // @ts-ignore
      grecaptcha.enterprise
        .execute(GS_RE_CAPTCHA_APP_ID, {
          action: "login",
        })
        .then((token: string) => {
          Promise.all([onTokenRetrieved(token)]);
        });
    });
  };
  return { getToken };
};
