import { FormCount, FormField, FormLabel } from "components/Form";
import { useTranslation } from "react-i18next";
import { EditEventCardProps } from "../Utils";

export type LabeledCountCardFormProps = {
  titleKey: string;
  stepPrecision: "0.1" | "1";
  includeUnitName: boolean;
  dataList?: Array<number>;
} & EditEventCardProps;

export const LabeledCountCardForm = ({
  event,
  onValueChange,
  titleKey,
  stepPrecision,
  includeUnitName,
  dataList,
}: LabeledCountCardFormProps) => {
  const { t } = useTranslation();
  return (
    <FormField>
      <FormLabel text={t(titleKey)} />
      <FormCount
        name="price"
        step={stepPrecision}
        value={event?.price}
        onChange={onValueChange}
        dataList={dataList}
      />
      {includeUnitName && <FormLabel text={event.unit_name} />}
    </FormField>
  );
};
