import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import CenterContainer from "components/common/CenterContainer";
import { FormEvent, useState } from "react";
import {
  FormBox,
  FormDate,
  FormField,
  FormLabel,
  FormName,
  FormTitle,
} from "components/Form";
import { ButtonBase, ButtonPrimary } from "components/common/Buttons";
import GenderDropDown from "./GenderDropDown";
import { getAllGenderOptions, User } from "models/models";
import { getHtmlObjValue } from "utils/obj";
import { useGetUserQuery, useUpdateUserMutation } from "services/user";
import { ErrorToast, SuccessToast, WarningToast } from "components/Toast";
import { ChangeResult } from "services/utils";
import { LoadingBox } from "components/common/LoadingBox";
import { extractDateStr } from "utils/date";

export const Profile = () => {
  const { t } = useTranslation();
  const { data: currentUser, isLoading } = useGetUserQuery();
  const [user, setUser] = useState<User>({
    id: -1,
    email: "",
    name: currentUser?.name ?? "",
    gender: currentUser?.gender ?? "",
    birth_date: currentUser?.birth_date ?? "",
    created_at: currentUser?.created_at ?? "",
  });
  const [changeResult, setChangeResult] = useState<ChangeResult>();
  const [updateUser, { isError, isLoading: isUpdatingEventLoading }] =
    useUpdateUserMutation();

  const onSaveChanges = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = await updateUser(user).unwrap();
    if (result.result === true) {
      setChangeResult({
        result: result.result,
        message: t(translations.profile.updateSuccessful),
      });
    } else {
      const message =
        result.message ?? t(translations.profile.errorGeneralSaveUser);
      setChangeResult({ result: false, message: message });
    }
    setTimeout(() => {
      setChangeResult(undefined);
    }, 2000);
    return false;
  };

  if (isLoading) {
    return <LoadingBox />;
  }

  const onValueChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const newUser = { ...user };
    newUser[e.target.name] = getHtmlObjValue(e);
    setUser(newUser);
  };

  return (
    <CenterContainer>
      <div className="m-2">
        {changeResult ? (
          changeResult.result ? (
            <SuccessToast
              title={t(translations.profile.updateToastTitle)}
              onClose={() => setChangeResult(undefined)}
            >
              {t(translations.profile.updateSuccessful)}
            </SuccessToast>
          ) : (
            <WarningToast
              title={t(translations.profile.updateToastTitle)}
              onClose={() => setChangeResult(undefined)}
            >
              {changeResult?.message ?? ""}
            </WarningToast>
          )
        ) : (
          <></>
        )}
        {isError ? (
          <ErrorToast
            title={t(translations.profile.updateToastTitle)}
            onClose={() => setChangeResult(undefined)}
          >
            {t(translations.profile.errorGeneralSaveUser)}
          </ErrorToast>
        ) : null}
      </div>
      <form onSubmit={onSaveChanges}>
        <FormBox>
          <FormTitle text={t(translations.profile.title)} />
          <FormField>
            <FormLabel text={t(translations.profile.nameLabel)} />
            <FormName name="name" text={user.name} onChange={onValueChange} />
          </FormField>
          <FormField>
            <FormLabel text={t(translations.profile.birthDateLabel)} />
            <FormDate
              name="birth_date"
              type="date"
              date={extractDateStr(user.birth_date)}
              onChange={onValueChange}
            />
          </FormField>
          <FormField>
            <FormLabel text={t(translations.profile.genderLabel)} />
            <GenderDropDown
              selectedId={user.gender}
              items={getAllGenderOptions()}
              onChange={onValueChange}
            />
          </FormField>

          {isUpdatingEventLoading ? (
            <ButtonBase
              disabled={true}
              className="dark:border-slate-400 dark:border-2"
            >
              <div className="self-center inline-block">
                <LoadingBox/>
              </div>
            </ButtonBase>
          ) : (
            <ButtonPrimary type="submit">
              {t(translations.profile.saveButton)}
            </ButtonPrimary>
          )}
        </FormBox>
      </form>
    </CenterContainer>
  );
};
