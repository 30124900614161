import React from "react";

export type Props = {
    title: string,
    placeholder: string,
    type: "email" | "text" | "password",
    name: string,
};
export const FormInput = React.forwardRef<HTMLInputElement, Props>(({title, placeholder, name, type}: Props, inputRef) => {
  return (
    <div className="mb-4">
      <label
        className="block text-gray-700 dark:text-slate-300 text-sm mb-2"
        htmlFor={name}
      >
        {title}
      </label>
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type={type}
        ref={inputRef}
        name={name}
        id={name}
        placeholder={placeholder}
      />
    </div>
  );
});
