import { Children } from "utils/reactUtils";

export type Props = Children & { className?: string };

export const NavBar = ({ children, className }: Props) => {
  const cs = "w-full pl-2 pr-2 mt-3 items-center flex " + className;
  return <ul className={cs}>{children}</ul>;
};

export const NavBarItem = ({ children, className = "" }: Props) => {
  const cs = className + " p-3 cursor-pointer hover:text-teal-600";
  return <li className={cs}>{children}</li>;
};
