import { CenterInside } from "layouts/CenterInside";
import MainLayout from "layouts/MainLayout";
import { Children } from "utils/reactUtils";

export const CommonPage = ({ children }: Children) => {
    return <MainLayout>
        <CenterInside>
            {children}
        </CenterInside>
    </MainLayout>;
};