import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";

export default function SearchItem({
  name,
  getFilterValue,
}: {
  name: string;
  getFilterValue: (key: string) => string;
}) {
  const { t } = useTranslation();
  const label = t(translations.searchInfo[name]);
  const value = getFilterValue(name);

  if (!label || !value) {
    return <></>;
  }
  return (
    <div className="rounded-xl mt-2 p-2 bg-slate-500 text-white inline-block">
      <label className="text-slate-200">{label}</label>
      <span className="font-bold">{value}</span>
    </div>
  );
}
