import { useNavigate } from "react-router-dom";

import { NavBarItem } from "./NavBar";

export default function NavLogo() {
  const navigate = useNavigate();
  return (
    <NavBarItem>
      <img onClick={() => navigate("/")} title="Dziennik zdarzeń" src="/Logo.png" alt="Logo" className="w-6 md:w-6 lg:w-6 transition ease-in-out hover:scale-125" />
    </NavBarItem>
  );
}
