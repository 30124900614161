import {
  FormDate,
  FormDescription,
  FormLabeledField,
  FormName,
  FormTitle,
} from "components/Form";
import { ButtonPrimary, ButtonSecondary } from "components/common/Buttons";
import { translations } from "locales/translations";
import FormCategory from "./FormCategory";
import { useTranslation } from "react-i18next";
import { Category } from "models/Category";
import { ChangeEvent } from "react";
import { SpinnerButton } from "components/common/SpinnerButton";

export type Props = {
  categoryId: number;
  categories: Category[];
  title: string;
  id: number;
  name: string;
  description: string;
  createdDate: string;
  onCategoryChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onValueChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onDeleteEvent?: () => void;
  showSpinner?: boolean;
  hideButtons?: boolean;
  EventTypeEditComponent: React.ReactNode | React.ReactNode[];
};

export const EditEventCard = ({
  categoryId,
  categories,
  createdDate,
  id,
  name,
  description,
  title,
  showSpinner,
  hideButtons,
  onCategoryChange,
  onValueChange,
  onDeleteEvent,
  EventTypeEditComponent,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <FormTitle text={title} />

      <FormLabeledField text={t(translations.editEvent.typeLabel)}>
        <FormCategory
          selectedCategoryId={categoryId}
          categories={categories ?? []}
          onChange={onCategoryChange}
        />
      </FormLabeledField>

      {EventTypeEditComponent}

      <FormLabeledField text={t(translations.editEvent.dateLabel)}>
        <FormDate
          name="created_date"
          type="datetime-local"
          date={createdDate}
          onChange={onValueChange}
        />
      </FormLabeledField>

      <FormLabeledField text={t(translations.editEvent.nameLabel)}>
        <FormName name="name" text={name} onChange={onValueChange} />
      </FormLabeledField>

      <FormLabeledField text={t(translations.editEvent.descriptionLabel)}>
        <FormDescription
          name="description"
          text={description}
          onChange={onValueChange}
        />
      </FormLabeledField>

      {showSpinner ? <SpinnerButton /> : <></>}
      {!showSpinner && !hideButtons ? (
        <div className="flex flex-row gap-2">
          <ButtonPrimary type="submit" className="w-full">
            {t(translations.editEvent.saveButton)}
          </ButtonPrimary>
          {id > 0 ? (
            <ButtonSecondary
              type="button"
              onClick={onDeleteEvent}
              className="basis-1/6"
              data-modal-toggle="popup-modal"
            >
              {t(translations.editEvent.removeButton)}
            </ButtonSecondary>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
