import { ReactComponent as PlusIcon } from "./plus.svg";

import { NavBarItem } from "./NavBar";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { useNavigate } from "react-router-dom";

export default function NavNewEvent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <NavBarItem>
      <PlusIcon onClick={() => navigate("/events/new")} title={t(translations.nav.new)} />
    </NavBarItem>
  );
}
