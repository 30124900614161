import { EditEventCardProps } from "../Utils";
import { LabeledCountCardForm } from "./LabeledCountCardForm";
import type { Event } from "models/Event";

export type LabeledCountCardFloatingStepFormProps = {
  titleKey: string;
  event: Event;
  includeUnitName: boolean;
  dataList?: Array<number>;
 } & EditEventCardProps;

export const LabeledCountCardFloatingStepForm = ({
  event,
  onValueChange,
  titleKey,
  includeUnitName,
  dataList,
}: LabeledCountCardFloatingStepFormProps) => {
  return (
    <LabeledCountCardForm
      event={event}
      onValueChange={onValueChange}
      stepPrecision="0.1"
      includeUnitName={includeUnitName}
      titleKey={titleKey}
      dataList={dataList}
    />
  );
};