export function isEmpty(obj: any | undefined | null): boolean {
  return obj === undefined || obj === null || Object.keys(obj).length === 0;
}

export function getHtmlObjValue(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
  return e.target.type === "number" && e.target.value ? parseInt(e.target.value, 0) : e.target.value;
}

export function createObj<T extends Record<string|number, any>>(obj: T) : T {
  Object.keys(obj).forEach(key => !obj[key] ? delete obj[key] : {});
  return obj;
}
