import { CenterInside } from "layouts/CenterInside";
import { ButtonPrimary, ButtonQuaternary } from "components/common/Buttons";
import { FormHeader, FormSubHeader } from "./FormHeader";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function LoginOptions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  return (
    <CenterInside>
      <div className="flex flex-col justify-between">
        <div className="flex flex-col items-center justify-between">
          <FormHeader>{t(translations.loginOptions.header)}</FormHeader>
          <FormSubHeader>
            {t(translations.loginOptions.subheader)}
          </FormSubHeader>
          <div className="flex flex-col items-center m-4">
            <ButtonQuaternary
              onClick={() => {
                if (search.has("type")) {
                  search["type"] = "email";
                } else {
                  search.append("type", "email");
                }
                setSearch(search);
              }}
              className="m-2"
            >
              {t(translations.loginOptions.loginByEmail)}
            </ButtonQuaternary>
            {/* <ButtonQuaternary
              onClick={() => navigate("/login?type=fb")}
              className="m-2 flex items-center"
            >
              <img alt="Facebook" src="/fb.png" className="w-6 mr-4" />{" "}
              <p>{t(translations.loginOptions.loginByFacebook)}</p>
            </ButtonQuaternary> */}
            <ButtonPrimary
              onClick={() => navigate("/register")}
              className="m-4"
            >
              {t(translations.loginOptions.register)}
            </ButtonPrimary>
          </div>
          <label className="m-8 hover:font-semibold hover:text-teal-800">
            <a href="/">{t(translations.loginOptions.goBack)}</a>
          </label>
        </div>
      </div>
    </CenterInside>
  );
}
