import { NavBar } from "./NavBar";
import NavLogo from "./NavLogo";
import NavTitle from "./NavTitle";

export default function NotLoggedInNav() {
  return (
    <NavBar>
      <NavLogo />
      <NavTitle />
    </NavBar>
  );
}
