import { ButtonBase } from "./Buttons";
import { LoadingBox } from "./LoadingBox";

export const SpinnerButton = () => {
    return (
      <ButtonBase disabled={true} className="dark:border-slate-400 dark:border-2">
        <div className="self-center inline-block">
          <LoadingBox />
        </div>
      </ButtonBase>
    );
  };