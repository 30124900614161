import { useState } from "react";
import { OnButtonClick } from "./common/Buttons";
import { LoadingBox } from "./common/LoadingBox";
import { ErrorIcon, SuccessIcon, WarningIcon } from "./Icons";

const CloseButton = ({ onClick = () => {} }: { onClick: OnButtonClick }) => {
  return (
    <button
      type="button"
      className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
      data-dismiss-target="#toast-success"
      aria-label="Close"
      onClick={onClick}
    >
      <span className="sr-only">Close</span>
      <svg
        aria-hidden="true"
        className="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        ></path>
      </svg>
    </button>
  );
};

export type Props = {
  title?: string;
  children?: React.ReactNode | React.ReactNode[];
  visible?: boolean;
  onClose?: () => void;
};

export const ErrorToast = ({ title, children, visible, onClose }: Props) => {
  if (!visible) {
    return <></>;
  }
  return (
    <div
      id="toast-danger"
      className="flex items-center p-4 m-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
      role="alert"
    >
      <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
        <ErrorIcon />
        <span className="sr-only">{title}</span>
      </div>
      <div className="ml-3 text-sm font-normal">{children}</div>
      {onClose ? <CloseButton onClick={onClose} /> : null}
    </div>
  );
};

export const SuccessToast = ({ title, children, visible, onClose }: Props) => {
  if (!visible) {
    return <></>;
  }
  return (
    <div
      id="toast-success"
      className="flex items-center p-4 m-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
      role="alert"
    >
      <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
        <SuccessIcon />
        <span className="sr-only">{title}</span>
      </div>
      <div className="ml-3 text-sm font-normal">{children}</div>
      {onClose ? <CloseButton onClick={onClose} /> : null}
    </div>
  );
};

export const WarningToast = ({ title, children, visible, onClose }: Props) => {
  if (!visible) {
    return <></>;
  }
  return (
    <div
      id="toast-warning"
      className="flex items-center m-4 p-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
      role="alert"
    >
      <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
        <WarningIcon />
        <span className="sr-only">{title}</span>
      </div>
      <div className="ml-3 text-sm font-normal">{children}</div>
      {onClose ? <CloseButton onClick={onClose} /> : null}
    </div>
  );
};

export const InfoToast = ({ title, children, visible, onClose }: Props) => {
  if (!visible) {
    return <></>;
  }
  return (
    <div
      id="toast-warning"
      className="flex items-center m-4 p-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
      role="alert"
    >
      <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-blue-500 bg-orange-100 rounded-lg dark:bg-blue-700 dark:text-orange-200">
        <span className="sr-only">{title}</span>
      </div>
      <div className="ml-3 text-sm font-normal">{children}</div>
      {onClose ? <CloseButton onClick={onClose} /> : null}
    </div>
  );
};

export const LoadingToast = ({ title, children, visible, onClose }: Props) => {
  if (!visible) {
    return <></>;
  }
  return (
    <div
      id="toast-warning"
      className="flex items-center p-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
      role="alert"
    >
      <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-blue-500 bg-orange-100 rounded-lg dark:bg-blue-700 dark:text-orange-200">
        <LoadingBox />
        <span className="sr-only">{title}</span>
      </div>
      <div className="ml-3 text-sm font-normal">{children}</div>
      {onClose ? <CloseButton onClick={onClose} /> : null}
    </div>
  );
};

export type ToastProps = {
  title: string;
  text: string;
  visible: boolean;
};
export type GetToastType = ToastProps;
export type SetToastType = ({
  title,
  text,
  visible,
}: {
  title?: string;
  text?: string;
  visible?: boolean;
}) => void;

export const useToast = (): [GetToastType, SetToastType] => {
  const [state, setState] = useState<ToastProps>({
    visible: false,
    title: "",
    text: "",
  });
  return [
    state,
    (props) => {
      const newState = { ...state };
      Object.entries(props).forEach(([key, value]) => {
        if (key !== null && key !== undefined) {
          newState[key] = value;
        }
      });
      setState(newState);
    },
  ];
};
